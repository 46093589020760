<template>
  <base-layout>
    <div class="card" style="border-radius:0px !important">
      <div class="card-header ">
        <!--strong>{{ person.first_name }} {{ person.middle_name }} {{ person.last_name }} </strong>
        ({{ person.country }})-->
        <div class="col-12  px-0 mb-1 ">
          <div class="person-header">
            <div class="row g-0 ">
              <div class="col-2 col-md-1  d-flex align-items-start justify-content-center p-0 pb-md-1 pt-2 pt-md-3">
                <img class="img-fluid rounded person-image" v-if="!person.vitae_picture"
                     src="@/assets/icon/avatar.png">
                <img class="img-fluid rounded person-image" v-if="person.vitae_picture"
                     :src="'https://fileserver-188ab.kxcdn.com/file/' + person.vitae_picture.split('/')[4]">
              </div>
              <div class="col-10 d-block d-md-none">
                <div class="card-body py-0 d-flex flex-column align-items-start ps-0 pt-2 pt-md-3">
                  <strong class="mb-0 ps-3">{{ person.title }} {{ person.display_name }}</strong>
                  <div class="my-0 ps-3 order-md-1">{{ person.city }} ({{ person.country }})</div>
                </div>
              </div>
              <div class="col-10 d-block d-md-none">
                <div class="card-body py-0 d-flex flex-column align-items-start ps-0 pt-0">
                <div class="pt-1 order-2">
                  <div v-if="person.vitae_text">
                    <div class="vitae" v-html="person.vitae_text"></div>
                    <button type="button" class="btn btn-sm btn-secondary py-0" data-bs-toggle="modal"
                            data-bs-target="#vitaeModal">
                      read more
                    </button>
                    <div class="modal fade  py-5" id="vitaeModal" tabindex="-1" data-bs-backdrop="false" aria-labelledby="vitaeModalLabel" aria-hidden="true">
                      <div class="modal-dialog  modal-fullscreen modal-dialog-centered  modal-dialog-scrollable">
                        <div class="modal-content note-modal">
                          <div class="modal-header">
                            <h5 class="modal-title" id="vitaeModalLabel">Biography</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body" v-html="person.vitae_text">

                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <!--div style="color:grey" v-else>
                    {{ wordings[currentLanguage].NO_BIOGRAPHY }}
                  </div-->
                </div>
                </div>
              </div>
              <div class="col-10 d-none d-md-block">
                <div class="card-body py-0 d-flex flex-column align-items-start ps-0 pt-0">
                  <h6 class="mb-0 ps-3">{{ person.title }} {{ person.display_name }}</h6>
                  <div class="my-0 ps-3 order-md-1">{{ person.city }} ({{ person.country }})</div>
                  <div class=" ps-3 order-2">
                    <div v-if="person.vitae_text">
                      <div class="vitae" v-html="person.vitae_text"></div>
                      <button type="button" class="btn btn-sm btn-secondary py-0" data-bs-toggle="modal"
                              data-bs-target="#vitaeModal2">
                        read more
                      </button>
                      <div class="modal fade  py-5" id="vitaeModal2" tabindex="-1" data-bs-backdrop="false"
                           aria-labelledby="vitaeModal2Label" aria-hidden="true">
                        <div
                            class="modal-dialog  modal-fullscreen modal-dialog-centered  modal-dialog-scrollable">
                          <div class="modal-content note-modal">
                            <div class="modal-header">
                              <h5 class="modal-title" id="vitaeModal2Label">Biography</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal"
                                      aria-label="Close"></button>
                            </div>
                            <div class="modal-body" v-html="person.vitae_text">

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div style="color:grey" v-else>
                      {{ wordings[currentLanguage].NO_BIOGRAPHY }}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="container-fluid px-3 mt-3 mb-1" style="line-height: 40px;">
      <div class="row justify-content-center justify-content-md-start mb-1 px-1">
        <div class="col px-2" style="max-width: 200px;">
          <FavoriteButton :fav-id="favId" v-if="person.id"></FavoriteButton>
        </div>
        <div class="col px-2" style="max-width: 200px;">
          <NoteButton :fav-id="favId" v-if="person.id"></NoteButton>
        </div>
      </div>

    </div>


    <ion-content id="wcpt-person-detail" class="has-header has-subheader has-bottom-banner">


      <div class="container-fluid">
        <div class="row row-cols-1 mt-1 pt-0">

          <div class="col-12 order-1 h-100 px-0">
            <div class="container-fluid p-0 px-1 px-md-2 mt-md-2">
              <div class="row ps-2  ps-md-1">
                <h6 class="col ps-3 ps-md-3" v-if="sessions && sessions.length > 0">
                  {{ wordings[currentLanguage].PERSON_SESSIONS_TITLE }} </h6>
              </div>
              <div
                  class="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 ps-md-2 g-2 g-md-4 row-eq-height justify-content-start">
                <template v-for="session in sessions" :key="session.id">
                  <session-item :session="session"></session-item>
                </template>
              </div>
            </div>
            <div class="container-fluid p-0 px-1 px-md-1 mt-md-2">
              <div class="row ps-2 mt-3">
                <h6 class="col ms-1 " v-if="presentations && presentations.length > 0">
                  {{ wordings[currentLanguage].PERSON_PRESENTATIONS_TITLE }}</h6>
              </div>
              <div class="ps-1 py-0">
                <presentation-list :presentations="presentations" hide-time="hideTime"
                                   :with-date="true"></presentation-list>
              </div>

            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapState} from "vuex";
import moment from "moment";
import PresentationList from "@/components/PresentationList";
import FavoriteButton from "@/components/favorite/FavoriteButton";
import NoteButton from "@/components/favorite/NoteButton";
import SessionItem from "@/components/SessionItem";

export default defineComponent({
  name: "SessionDetail",
  data() {
    return {
      person: {},
      sessions: [],
      presentations: [],
      collapsed: true,
      favId: null,
      conflicts: [],
    };
  },
  components: {
    IonContent,
    "presentation-list": PresentationList,
    FavoriteButton,
    NoteButton,
    SessionItem
  },
  methods: {
    ...mapActions("persons", [
      "getPersonById",
      "getAffiliations",
      "getSessions",
      "getPresentations"
    ]),

    ...mapActions('googleanalytics', ['trackWithLabel']),
    collapse() {
      this.collapsed = !this.collapsed;
    },

    collapseStyle() {
      if (this.collapsed) {
        return {
          'max-height': '140px',
          'min-height': '140px !important',
        };
      } else {
        return {
          'max-height': 'none',
          'min-height': '140px !important',
        };
      }
    },
  },

  computed: {
    ...mapState('wordings', ['wordings', 'currentLanguage']),
  },

  async created() {
    console.log('persondetail')
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(route) {
        if (route.name == 'Person Detail') {
          this.moment = moment;
          this.person = await this.getPersonById(route.params.id);
          this.favId = 'persons/' + this.person.id;
          this.sessions = await this.getSessions(this.person.id);
          //let affiliations = await this.getAffiliations(this.person.id);
          //this.conflicts = affiliations.coi;
          this.presentations = (await this.getPresentations(this.person.id)).sort((a, b) => {
            return new Date(a.start_time) - new Date(b.start_time);
          });
          this.trackWithLabel(this.person.display_name)
        }
      }
    }
  }
});
</script>
<style lang="scss">
.person-image {
  object-fit: cover;
  aspect-ratio: 1/1;
  min-width: 50px;
}

.person-header {
  border-radius: 0;
  border: none !important;

  .actn-icon {
    font-size: 1.3rem !important;
  }

  .actn-text {
    font-size: 0.7rem !important;
    line-height: 0.7rem !important;
  }

  .vitae {
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    display: -webkit-box;

    -webkit-box-orient: vertical;
  }
}

@media(max-width: 767px) {
  .person-header {
    border-radius: 0;
    border: none !important;
  }
}

.note-modal {
  -moz-box-shadow: -4px 4px 5px #b6b6b6;
  -webkit-box-shadow: -4px 4px 5px #b6b6b6;
  box-shadow: -4px 4px 5px #b6b6b6;
}

</style>
